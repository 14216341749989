import { getMessage, handleAutoComplete, routeNavigationUrl } from 'helpers/util-common';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Form, Modal } from 'semantic-ui-react';
import TextArea from 'shared-components/TextArea';
import CustomGroupRendererSearch from '../custom-group-renderer-search';
import * as _func from './function';


class AddAssociateMemberForm extends Component {

    state = {
        isLoading: false,
        searchResultsData: [],
        showNoResults: false,
        showErrorMessage: false,
        searchValue: '',
        error: getMessage(this.props.messageCodes, '8159.text'),
        showConfirmationModal: false,
        descMessage: '',
        renderSelectedValueFromSearchList: []
    }
    componentDidMount() {
        _func.setClassInstance(this);
        handleAutoComplete()
    }
    render() {
        const { isLoading, searchResultsData, showNoResults, showErrorMessage, error, searchValue, renderSelectedValueFromSearchList } = this.state;
        const { close, handleSubmit, submitting, PAGE_TYPE, USER_PRIVILEGE } = this.props;

        return (
            <div data-testid='addAssociateModal'>
                <Modal open='true'>
                    <Form onSubmit={handleSubmit(_func.associateMemberIntoFeeGroup)}>
                        <Modal.Header>Associate Member(s)<i data-testid="modalClose" className="close" title="close" onClick={() => close(false)}>&#x2716;</i></Modal.Header>
                        <Modal.Content scrolling>

                            <Modal.Description style={{ 'min-height': '370px' }}>
                                <div data-testid="SearchMember" className="mt20">
                                    <Field name="AssociateMember" type="text"
                                        component={CustomGroupRendererSearch}
                                        placeholder="Search Member by Account Number, Name"
                                        label="Search Member" required={true}
                                        maxLength="100"
                                        showNoResults={showNoResults}
                                        results={searchResultsData}
                                        isLoading={isLoading}
                                        onChange={(even, val) => _func.handleSearh(val)}
                                        onResultSelect={_func.handleResultSelect}
                                        isOrganizationSearch={true}
                                        value={searchValue}
                                        className="autocompleteOff"
                                    />

                                    {showErrorMessage && (
                                        <span className="errorMessage mt10">{error}</span>
                                    )}
                                </div>
                                <div className="mt20">
                                    <Field component={TextArea} type='text' label='Reason for update'
                                        name='UpdateReason' required={true} maxLength='200'
                                        placeholder="Please enter reason for update"
                                    />

                                </div>
                                <div className="tableWrapper paddingLR0 mt20">
                                    <table className="customTable">
                                        <thead>
                                            <tr>
                                                <th width="15%">Account</th>
                                                <th width="25%">Name</th>
                                                <th width="20%">Member Type</th>
                                                <th width="30%">Organization</th>
                                                <th width="10%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderSelectedValueFromSearchList.length > 0 &&
                                                renderSelectedValueFromSearchList.map((item, index) => {
                                                    return <tr>
                                                        <td><a href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.MEMBER), USER_PRIVILEGE.VIEW, [item.AccountNumber])}`} target="_blank">{item.AccountNumber}</a></td>
                                                        <td>{item.Name}</td>
                                                        <td>{item.MembershipTypeName}</td>
                                                        <td>{item.Organization}</td>
                                                        <td><a title="Remove" data-testid="removeItem" class="closeBtn" onClick={() => _func.removeMember(item.MemberId)}><i aria-hidden="true" class="trash icon"></i></a></td>
                                                    </tr>

                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Description>

                        </Modal.Content>
                        <Modal.Actions>
                            <div data-testid="ModalSubmit" className="pl0 mt20">
                                <Button primary type="submit" data-testid='submitButton' disabled={submitting}>Assign</Button>
                                <Button secondary data-testid='cancelButton' onClick={() => close(false)}>Cancel</Button>
                            </div>
                        </Modal.Actions>
                    </Form>
                </Modal>

            </div>
        )
    }
}

export default (reduxForm({
    form: 'AddAssociateMemberForm',
    validate: _func.validateHandler
})(AddAssociateMemberForm));

