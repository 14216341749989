import React from "react";
import { Form } from "semantic-ui-react";

const TextArea = ({ isCounterVisible, label, input, name, placeholder, type, maxLength, disabled, required, rows,  meta: { touched, error } }) => {
    const maximumLength = maxLength ? maxLength : 200;
    const maximumRows = rows ? rows : 3

    const getRemainingCounter = () => {
        const { value } = input;
        if (maximumLength && value.length) {
            return maximumLength - value.length;
        }
        return maximumLength;
    }
    return (<div>
        <label className="label" htmlFor={input.name}>
            {required && (<i aria-hidden="true" className="asterisk  icon"></i>)}
            {label}
        </label>

        <Form.TextArea
            {...input}
            data-testid='textareaSelect'
            placeholder={placeholder}
            className={touched && error ? "error" : ''}
            type={type}
            name={name}
            id={input.name}
            maxLength={maximumLength}
            maxRows = {maximumRows}
            disabled={disabled && disabled ? disabled : false}
        />
        {isCounterVisible && <span style={{ float: 'right', color: '#553d3d' }}>Character left:{getRemainingCounter()}</span>}
        {touched && (error && <span className="errorMessage">{error}</span>)}
    </div>);
}

export default TextArea;
