import { anchorTextLengthValidate, routeNavigationUrl, cacheManager, getMessage } from "helpers/util-common";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Grid } from "semantic-ui-react";
import CustomTooltip from "shared-components/Tooltip";
import userImgBig from "../../../../../assets/images/user-circle-big.png";
import { getPaidStatusName } from "../../common-functions";
import { ActiveMemberStatusId, MembershipTypes, PaidStatus } from "../../members.model";
import IdentityDelegationAlertPopup from "../../../../../shared-components/CustomAlertModal/CustomIdentityDelegationAlert";
class MemberBannerHeader extends Component {
  state = {
    modifiedByName: "",
    isEditMode: false,
    showDelegationAlertPopup: false
  };

  componentWillMount() {
    this.getModifiedByUserName(this.props.initialValues.ModifiedBy, this.props.currentModifiedType);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.currentModifiedById !== this.props.currentModifiedById) {
      this.getModifiedByUserName(nextProps.currentModifiedById, nextProps.currentModifiedType);
      return true;
    }

    if (nextProps.modifiedDate !== this.props.modifiedDate || nextState.modifiedByName !== this.state.modifiedByName || nextProps.memberTypeId !== this.props.memberTypeId || nextProps.FacilityName !== this.props.FacilityName) {
      return true;
    }
    if (nextProps.initialValues.FacilityName !== this.props.initialValues.FacilityName) {
      return true;
    }
    if (nextState.showDelegationAlertPopup !== this.state.showDelegationAlertPopup) {
      return true;
    }
  }

  getModifiedByUserName = (userId, modifiedType) => {
    if (userId === 0 || userId === "") {
      this.setState({ modifiedByName: "" });
    } else {
      modifiedType === 0 ? this.getUserName(userId) : this.getMemberName(userId);
    }
  };

  getUserName = (userId) => {
    this.props.getUserName(userId, (response) => {
      if (response) {
        const modifiedByName = response;
        this.setState({ modifiedByName });
      }
    });
  };

  getMemberName = (memberId) => {
    this.props.getMemberIdByMemberName(memberId, (response) => {
      if (response) {
        const modifiedByName = response;
        this.setState({ modifiedByName });
      }
    });
  };

  clickIdentityDelegationURLMember = (e) => {
    const identityDelegationURL = `${process.env.REACT_APP_DELEGATION_URL}?accountnumber=${this.props.initialValues.AccountNumber}&useremail=${this.props.initialValues.Email}&appcode=MEMBER`;
    window.open(identityDelegationURL, "_blank");
    this.setState({ showDelegationAlertPopup: false })
    e.preventDefault();
  };

  closeDelegationAlertModel = () => {
    this.setState({ showDelegationAlertPopup: false });
  }

  openDelegationAlertModel = (e) => {
    this.setState({ showDelegationAlertPopup: true });
    e.preventDefault();
  }

  render() {
    const { initialValues, membershipTypeList, PAGE_TYPE, USER_PRIVILEGE, memberMasterDetails } = this.props;
    const { modifiedByName, showDelegationAlertPopup } = this.state;
    let membershipType = "";
    if (membershipTypeList && membershipTypeList.length > 0) {
      let index = membershipTypeList.findIndex((item) => item.key === initialValues.MemberTypeId);
      if (index !== -1) {
        membershipType = membershipTypeList[index].text;
      }
    }
    const paidStatus = getPaidStatusName(initialValues.PaidStatusId);
    const flagFeature = JSON.parse(cacheManager.getItem("featureFlag"));

    return (
      <div className="bannerMember">
        <div className="ui container">
          <form>
            <div className="memberInfo">
              <figure className="memberImg">
                <img src={userImgBig} alt="" />
              </figure>
              <div className="memberData">
                <CustomTooltip icon={<span className="memberName ellip">{initialValues.Name}</span>} content={initialValues.Name} />
                <span className="memberAccount">
                  <em>Account Number:</em> {initialValues.AccountNumber}
                </span>
              </div>
              <div className="memberRole">
                <span className="roleTypenName">{membershipType}</span>
                {initialValues.IsProfileMember && <span className="roleTypenName">Profile Member</span>}
              </div>
              <div className="updatedByInfo">
                <span className="paidStatus">
                  <em>Paid Status: </em>
                  <span className="pdStatus">{paidStatus}</span>
                </span>
                <span className="lastUpdated">
                  Last Updated by <strong>{modifiedByName}</strong> on {initialValues.lastModifiedDate}
                </span>
                {flagFeature.IdentityDelegation && JSON.parse(cacheManager.getItem("hasDelegationAccess")) === true && (
                  <div>
                    <button data-testid="IdentityDelegationMemberClick" className="identityDelegationMember" onClick={this.openDelegationAlertModel}>
                      IDENTITY DELEGATION
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="memberRelatedInfo">
              <Grid divided="vertically" columns={4}>
                <Grid.Row>
                  <Grid.Column>
                    <span className="titleLabel">Join Date</span>
                    <span className="titleInfo">{initialValues.JoinDate}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="titleLabel">Facility</span>
                    <span className="titleInfo">
                      <CustomTooltip
                        icon={
                          <a className="ellip noPointer" ref={(e) => anchorTextLengthValidate(e)}>
                            {initialValues.FacilityName}
                          </a>
                        }
                        content={initialValues.FacilityName}
                        position={"bottom center"}
                      />
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="titleLabel">Email</span>
                    <CustomTooltip
                      icon={
                        <span className="titleInfo">
                          <a className="ellip" ref={(e) => anchorTextLengthValidate(e)} href={`mailto:${initialValues.Email}`}>
                            {initialValues.Email}
                          </a>
                        </span>
                      }
                      content={initialValues.Email}
                      position={"bottom center"}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <span className="titleLabel">Phone</span>
                    <span className="titleInfo">{initialValues && initialValues.PhoneNumber ? initialValues.PhoneNumber : ""}</span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <span className="titleLabel">Paid Date</span>
                    <span className="titleInfo">{initialValues.PaidStatusId === PaidStatus.Paid ? initialValues.PaidDate : ""}</span>
                  </Grid.Column>
                  {(initialValues.MemberTypeId === MembershipTypes.Representative || initialValues.MemberTypeId === MembershipTypes.cooperativeAgreementRep) && (
                    <Grid.Column>
                      <span className="titleLabel">Organization</span>
                      {initialValues.OrgAccountNumber ? (
                        <CustomTooltip
                          icon={
                            <span className="titleInfo">
                              <a className="ellip" ref={(e) => anchorTextLengthValidate(e)} href={`${routeNavigationUrl(PAGE_TYPE.DETAIL(PAGE_TYPE.ORGANIZATION), USER_PRIVILEGE.VIEW, [initialValues.OrgAccountNumber])}`}>
                                {initialValues.OrgName} {initialValues.OrgAccountNumber ?? ""}
                              </a>
                            </span>
                          }
                          content={`${initialValues.FacilityName ?? ""} ${initialValues.OrgAccountNumber ?? ""}`}
                          position={"bottom center"}
                        />
                      ) : initialValues.FacilityName ? (
                        <span className="titleInfo">
                          <CustomTooltip icon={<a className="ellip noPointer" ref={(e) => anchorTextLengthValidate(e)}>{`${initialValues.FacilityName ?? ""} ${initialValues.FacilityAccountNumber ?? ""}`}</a>} content={`${initialValues.FacilityName ?? ""} ${initialValues.FacilityAccountNumber ?? ""}`} position={"bottom center"} />
                        </span>
                      ) : null}
                    </Grid.Column>
                  )}

                  <Grid.Column>
                    <span className="titleLabel">Account Status</span>
                    <span className="titleInfo">{initialValues.StatusName}</span>
                  </Grid.Column>
                  {initialValues.McsStatusMasterId !== ActiveMemberStatusId && (
                    <Grid.Column>
                      <span className="titleLabel">Historical Reason</span>
                      <CustomTooltip icon={<span className="titleInfo">{initialValues.HistoricalReason}</span>} content={initialValues.HistoricalReason} position={"bottom center"} />
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    <span className="titleLabel">Copyright Acceptance</span>
                    <span className="titleInfo">{initialValues.CopyrightAcceptance}</span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </form>
        </div>
        <IdentityDelegationAlertPopup showDelegationAlertPopup={showDelegationAlertPopup} handleClick={this.clickIdentityDelegationURLMember} handleDismiss={this.closeDelegationAlertModel} />
      </div>
    );
  }
}

export default reduxForm({
  form: "memberBannerHeader",
})(MemberBannerHeader);
