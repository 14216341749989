import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const IdentityDelegationAlertPopup = ({ handleClick, handleDismiss, showDelegationAlertPopup }) => (

<Modal data-testid='DelegationAlertModal' open={showDelegationAlertPopup} className="tiny errormodalbox">
    <Modal.Header><Icon name='warning sign' />Alert<i onClick={handleDismiss} className="close" title="close">&#x2716;</i></Modal.Header>
    <Modal.Content>
        <Modal.Description>
            <p>Please save any existing information already open in the member app with your credentials.<br/>Are you sure you want to proceed with identity delegation?<br/><br/></p>

            <div class="ui container center aligned">
                <Button secondary style={{ marginRight: '10px' }} onClick={handleClick}>Yes</Button>
                <Button secondary onClick={handleDismiss}>No</Button>
            </div>

        </Modal.Description>
    </Modal.Content>
</Modal>
)

export default IdentityDelegationAlertPopup;
